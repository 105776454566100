import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import logo from "../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { Call, Schedule } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";

function AutoLlamadas() {
  const [firstName, setFirstName] = useState("");
  const [dealershipName, setDealershipName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("mx");
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const handleSubmit = async (event) => {
    let newDealershipName = dealershipName
      .toLowerCase()
      .replace("automotriz", " ");

    event.preventDefault();
    const url = `https://createprospectdemocall-5vxz7xv2yq-uc.a.run.app?prospect_first_name=${firstName}&agency_name=${newDealershipName}&to_number=${phoneNumber}&from_number=529615670947`;
    // const url = `https://82b3-2604-3d09-cd82-a800-9d0c-7d57-916-3132.ngrok-free.app/twilio-voice-webhook/make-call/?prospect_first_name=${firstName}&agency_name=${dealershipName}&to_number=${phoneNumber}`;
    // url.searchParams.append("", firstName);
    // url.searchParams.append("", dealershipName);
    // url.searchParams.append("", phoneNumber);
    // url.searchParams.append("country", encodeURIComponent(country));

    // await fetch(url, {
    //   method: "GET",
    //   noCors: true,
    // }).then((res) => console.log(res.json()));

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
    // if (response.status === 200) {
    //   alert("Form submitted successfully!");
    // } else {
    //   alert("Failed to submit the form.");
    // }
  };

  const CallToAction = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        bgcolor="#001133"
        borderRadius={isSmallScreen ? 0 : 10}
        padding="1em"
        sx={{
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{
            color: "white",
            margin: "1em 0",
            fontWeight: "bold",
          }}
        >
          Descubra cómo nuestro sistema de llamadas IA puede transformar su
          concesionario
        </Typography>
        <Typography
          variant="h6"
          align="center"
          style={{
            color: "white",
            margin: "1em 0",
            maxWidth: "600px",
          }}
        >
          Programe una reunión con Brenda, nuestra especialista en ventas, y
          obtenga toda la información que necesita para impulsar su negocio.
        </Typography>
        <Button
          className="callBtn"
          type="submit"
          variant="contained"
          color="secondary"
          href="https://calendly.com/brenda-fuentes-jaspervocal/autollamas-inteligentes"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 1em",
            fontSize: "16px",
          }}
        >
          programar ahora
          <CalendarMonthIcon style={{ marginLeft: "10px" }} />
        </Button>
      </Box>
    );
  };

  return (
    <div className="full-page-background">
      <Grid container spacing={2} direction={isSmallScreen ? "column" : "row"}>
        <Grid item xs={12} md={7}>
          <Container className="App" maxWidth="sm">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>AutoLlamadas Inteligentes</h1>
            <h3>
              Revoluciona Tu Concesionaria con Nuestro Sistema de Llamadas
              Automáticas
            </h3>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nombre"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <TextField
                label="Nombre del concesionario"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={dealershipName}
                onChange={(e) => setDealershipName(e.target.value)}
                required
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                      }}
                      required
                    >
                      <MenuItem value="mx">🇲🇽 (+52)</MenuItem>
                      {/* <MenuItem value="es">🇪🇸 (+34)</MenuItem> */}
                      {/* <MenuItem value="us">🇺🇸 (+1)</MenuItem> */}
                      <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                  {/* <TextField
              label="Número de teléfono"
              variant="outlined"
              fullWidth
              margin="normal"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
             */}
                  <Box width={"100%"}>
                    <br />
                    <PhoneInput
                      country={country}
                      value={phoneNumber}
                      disableDropdown={true}
                      disableCountryGuess={true}
                      onChange={setPhoneNumber}
                      enableLongNumbers={true}
                      inputStyle={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                        height: "56px",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <h3>
                Al presionar, recibirá una llamada de demostración sin costo de
                este número:
                <br />
                <b>+52 961 567 0947</b>
              </h3>
              <br />
              <Button
                className="callBtn"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                <h2
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Presione aquí - demostración gratuita
                </h2>
                <div style={{ width: "20px" }} />
                <Call className="callIcon" />
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <CallToAction />
        </Grid>
      </Grid>
    </div>
  );
}

export default AutoLlamadas;
