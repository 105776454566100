import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import logo from "../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { Call, Schedule } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";

function Espana() {
  const [nombreEncuestado, setNombreEncuestado] = useState("");
  const [temaEspecifico, setTemaEspecifico] = useState("");
  const [areaEspecifica, setAreaEspecifica] = useState("");
  const [dealershipName, setDealershipName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("es");
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const handleSubmit = async (event) => {
    let newDealershipName = dealershipName
      .toLowerCase()
      .replace("automotriz", " ");

    event.preventDefault();
    const url = `https://createespanaerospectdemocall-5vxz7xv2yq-uc.a.run.app?nombre_encuestado=${nombreEncuestado}&tema_especifico=${temaEspecifico}&area_especifica=${areaEspecifica}&to_number=${phoneNumber}&from_number=34699318101`;

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
  };

  const CallToAction = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        bgcolor="#001133"
        borderRadius={isSmallScreen ? 0 : 10}
        padding="1em"
        sx={{
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{
            color: "white",
            margin: "1em 0",
            fontWeight: "bold",
          }}
        >
          Descubra cómo nuestro sistema de llamadas IA puede transformar su
          empresa en España
        </Typography>
        {/* <Typography
          variant="h6"
          align="center"
          style={{
            color: "white",
            margin: "1em 0",
            maxWidth: "600px",
          }}
        >
          Programe una reunión con Alvaro, nuestra especialista en ventas, y
          obtenga toda la información que necesita para impulsar su negocio.
        </Typography>
        <Button
          className="callBtn"
          type="submit"
          variant="contained"
          color="secondary"
          href="https://calendly.com/brenda-fuentes-jaspervocal/autollamas-inteligentes"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5em 1em",
            fontSize: "16px",
          }}
        >
          programar ahora
          <CalendarMonthIcon style={{ marginLeft: "10px" }} />
        </Button> */}
      </Box>
    );
  };

  return (
    <div className="full-page-background">
      <Grid container spacing={2} direction={isSmallScreen ? "column" : "row"}>
        <Grid item xs={12} md={7}>
          <Container className="App" maxWidth="sm">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>AutoLlamadas Inteligentes</h1>
            <h3>
              Revoluciona Tu Concesionaria con Nuestro Sistema de Llamadas
              Automáticas
            </h3>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nombre"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={nombreEncuestado}
                onChange={(e) => setNombreEncuestado(e.target.value)}
                required
              />
              <TextField
                label="Tema específico"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={temaEspecifico}
                onChange={(e) => setTemaEspecifico(e.target.value)}
                required
              />
              <TextField
                label="Area específica"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={areaEspecifica}
                onChange={(e) => setAreaEspecifica(e.target.value)}
                required
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                      }}
                      required
                    >
                      {/* <MenuItem value="mx">🇲🇽 (+52)</MenuItem> */}
                      <MenuItem value="es">🇪🇸 (+34)</MenuItem>
                      {/* <MenuItem value="us">🇺🇸 (+1)</MenuItem> */}
                      <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box width={"100%"}>
                    <br />
                    <PhoneInput
                      country={country}
                      value={phoneNumber}
                      disableDropdown={true}
                      disableCountryGuess={true}
                      onChange={setPhoneNumber}
                      enableLongNumbers={true}
                      inputStyle={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                        height: "56px",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <h3>
                Al presionar, recibirá una llamada de demostración sin costo de
                este número:
                <br />
                <b>+34 699 31 81 01</b>
              </h3>
              <br />
              <Button
                className="callBtn"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                <h2
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Presione aquí - demostración gratuita
                </h2>
                <div style={{ width: "20px" }} />
                <Call className="callIcon" />
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <CallToAction />
        </Grid>
      </Grid>
    </div>
  );
}

export default Espana;
