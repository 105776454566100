import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
} from "@mui/material";
import logo from "./assets/logo.png";
import "./App.css";
import { ArrowForward, Call } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";

function App() {
  const [firstName, setFirstName] = useState("");
  const [dealershipName, setDealershipName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("mx");

  return <div className="full-page-background"></div>;
}

export default App;
