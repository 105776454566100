import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import logo from "../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { Call, Schedule } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";

function _3Common() {
  const [coordinatorName, setCoordinatorName] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [organizerName, setOrganizerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("ca");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = `https://create3commondemocall-5vxz7xv2yq-uc.a.run.app?coordinator_name=${coordinatorName}&event_title=${eventTitle}&to_number=${phoneNumber}&organizer_name=${organizerName}&from_number=4318159527`;

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Call initiated successfully!");
        } else {
          alert("Failed to initiate the call.");
        }
      });
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>3Common Phone Call DEMO</h1>
          <h3>
            Testing website for 3Common's Phone Call DEMO with JasperVOCAL
          </h3>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Event coordinator name"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              fullWidth
              margin="normal"
              value={coordinatorName}
              onChange={(e) => setCoordinatorName(e.target.value)}
              required
            />
            <TextField
              label="Event title"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              fullWidth
              margin="normal"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              required
            />
            <TextField
              label="Event organization name"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              fullWidth
              margin="normal"
              value={organizerName}
              onChange={(e) => setOrganizerName(e.target.value)}
              required
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={phoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              After clicking to receive the DEMO call, you will receive a call
              from the following phone number: <b>+1 (431) 815-9527</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Click here to receive the DEMO call
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
}

export default _3Common;
